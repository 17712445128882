<template>
    <div>
        <div class="content">
            <h2 class="has-text-primary-dark">Integración</h2>
        </div>
    </div>
</template>

<script>
// TODO: Implement "Integracion" view
export default {
    metaInfo: {
        title: 'Integración'
    },
}
</script>

<style>

</style>
